/**
 * ID: bh-global-css
 * Name: global.scss
 * Description: App-specific global themes. Applied after base and canned stylesheets applied.
 * Version: 4
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Adjust cta-button style
 * 2021-07-27 - MW - v3: Added missing alert danger class
 * 2022-05-25 - MW - v4: Split global.scss into base.all.scss, base.desktop.scss, base.mobile.scss, canned.scss
 */

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* BH-Starter CSS utils */
@import "theme/canned.scss";
@import "theme/base.all.scss";
@import "theme/base.mobile.scss";
@import "theme/base.desktop.scss";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

.wide-modal .modal-wrapper {
  max-width: none !important;
  height: auto !important;
  width: auto !important;
}

.small-modal .modal-wrapper {
  max-width: none !important;
  height: auto !important;
  width: auto !important;
}

.end-button {
  min-width: 180px !important;
  display: flex;
  align-items: flex-end;

  &.web-header-link {
    justify-content: flex-end !important;
  }
}

@media (min-width: 768px) {
  .modal-wrapper.sc-ion-modal-md,
  modal-wrapper.sc-ion-modal-ios {
    border-radius: 16px;
  }

  .wide-modal {
    &.super-wide .modal-wrapper {
      max-width: 1400px !important;
    }

    &.fullscreen .modal-wrapper {
      max-width: unset !important;
    }

    .modal-wrapper {
      width: 100%;
      height: 100%;
      max-width: 1100px;
    }

    &.floating {
      .modal-wrapper {
        width: 95%;
        height: 95%;
      }
    }
  }

  .small-modal {
    &.super-wide .modal-wrapper {
      max-width: 1400px !important;
    }

    &.fullscreen .modal-wrapper {
      max-width: unset !important;
    }

    .modal-wrapper {
      width: 100px;
      height: 100px;
      max-width: 1100px;
      border-radius: 20px;
    }

    &.floating {
      .modal-wrapper {
        width: 95%;
        height: 95%;
      }
    }
  }

  .custom-modal {
    &.super-wide .modal-wrapper {
      max-width: 1400px !important;
    }

    &.fullscreen .modal-wrapper {
      max-width: unset !important;
    }

    .modal-wrapper {
      height: 700px;
      width: 1100px;
      max-width: 1100px;
    }

    &.floating {
      .modal-wrapper {
        width: 95%;
        height: 95%;
      }
    }
  }

  .publish-modal {
    &.super-wide .modal-wrapper {
      max-width: 1400px !important;
    }

    &.fullscreen .modal-wrapper {
      max-width: unset !important;
    }

    .modal-wrapper {
      // height: auto;
      min-height: 420px;
      width: 600px;
      max-width: 1100px;
    }

    &.floating {
      .modal-wrapper {
        width: 95%;
        height: 95%;
      }
    }
  }

  .print-modal {
    &.super-wide .modal-wrapper {
      max-width: 1400px !important;
    }

    &.fullscreen .modal-wrapper {
      max-width: unset !important;
    }

    .modal-wrapper {
      max-height: 500px;
      min-height: 500px;
      width: 600px;
      max-width: 1100px;
    }

    &.floating {
      .modal-wrapper {
        width: 95%;
        height: 95%;
      }
    }
  }

  .schedule-modal {
    &.super-wide .modal-wrapper {
      max-width: 1400px !important;
    }

    &.fullscreen .modal-wrapper {
      max-width: unset !important;
    }

    .modal-wrapper {
      max-height: 500px;
      min-height: 500px;
      width: 700px;
      max-width: 700px;
    }

    &.floating {
      .modal-wrapper {
        width: 95%;
        height: 95%;
      }
    }
  }

  .copy-day-modal {
    &.super-wide .modal-wrapper {
      max-width: 1400px !important;
    }

    &.fullscreen .modal-wrapper {
      max-width: unset !important;
    }

    .modal-wrapper {
      // min-width: 600px;
      max-width: 1100px;
    }

    &.floating {
      .modal-wrapper {
        width: 95%;
        height: 95%;
      }
    }
  }

  .search-modal {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0);

    // &.super-wide .modal-wrapper {
    //   max-width: 1400px !important;
    // }

    // &.fullscreen .modal-wrapper {
    //   max-width: unset !important;
    // }

    .modal-wrapper {
      border-radius: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: none;
      // position: absolute;
      // width: 1371px !important;
      // height: 663px !important;
      // top: calc(100% - (500px));
      // left: calc(100% - (766px)) !important;
      position: absolute;
      width: 100% !important;
      height: 84% !important;
      //width: 1371px !important;
      //height: 650px !important;
      top: calc(100% - (84%));
      //left: calc(100% - (88%)) !important;
      // height: 600px;
      // width: 1242px;
      //max-width: 1100px;
    }

    // &.floating {
    //   .modal-wrapper {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
  }

  .assignment-modal {
    .modal-wrapper {
      width: 100%;
      max-width: 1100px;
      height: 100%;
      max-height: 700px;
    }
  }

  .side-modal {
    &.super-skinny .modal-wrapper {
      max-width: 400px !important;
    }

    &.fullscreen .modal-wrapper {
      max-width: unset !important;
    }

    .modal-wrapper {
      width: 100%;
      height: 100%;
      max-width: 400px;
      right: 80px;
    }

    &.floating {
      .modal-wrapper {
        width: 95%;
        height: 95%;
        right: 80px;
      }
    }
  }
}

.wide-modal {
  backdrop-filter: blur(4px);

  &.floating {
    .modal-wrapper {
      background: transparent;
      box-shadow: none;
    }
  }
}

.small-modal {
  backdrop-filter: blur(4px);
  --border-radius: 20px;
  --height: 600px;
  --width: 700px;

  .modal-wrapper {
    width: 700px;
    height: 1100px;
    max-width: 1100px;
  }

  &.floating {
    .modal-wrapper {
      background: transparent;
      box-shadow: none;
    }
  }
}

.small-modal {
  &.super-wide .modal-wrapper {
    max-width: 1400px !important;
  }

  &.fullscreen .modal-wrapper {
    max-width: unset !important;
  }

  .modal-wrapper {
    width: 100px;
    height: 100px;
    max-width: 1100px;
  }

  &.floating {
    .modal-wrapper {
      width: 95%;
      height: 95%;
    }
  }
}

.section-header {
  margin-bottom: 24px;
  display: flex;
}

.section-title {
  font-weight: bold;
  font-size: 20px;
  flex: 1 1 auto;
  text-transform: capitalize;
}

.section-subtitle {
  margin-top: 48px;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
}

.section-content {
  margin-top: 24px;
}

.section-footer {
  padding: 16px 0;
  margin-top: 24px;
  border-top: 1px solid #eee;
  // text-align: center;

  ion-button {
    width: 200px;

    ion-icon {
      margin-right: 8px;
    }
  }
}

.control-container {
  display: flex;
  // align-items: center;
  justify-content: center;

  ion-button {
    min-width: 180px;

    ion-icon {
      font-size: 24px;
      margin-right: 8px;
    }
  }
}

.navigation-controls {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  padding-bottom: 8px;
  font-size: 14px;
  // margin-top: 16px;
  color: #000;
  opacity: .6;

  &:hover {
    text-decoration: underline;
  }

  ion-icon {
    font-size: 18px;
    padding-right: 8px;
  }
}

.trigger-event-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--ion-color-success);
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  margin: 48px 0;

  &.enable-event {
    border: 1px solid var(--ion-color-success);
  }

  .trigger-text-container {
    font-size: 16px;
    flex: 1 1 auto;

    .trigger-text-title {
      font-weight: bold;
      margin-bottom: 16px;
    }

    .trigger-text-body {
      font-size: 15px;
      margin-right: 16px;
    }
  }

  ion-button {
    --border-radius: 12px;
    --border-radius: 4px;
    ion-icon {
      padding-right: 8px;
    }
  }
}

ion-title {
  text-transform: capitalize;
  font-weight: bold;
}

.footer-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  ion-button {
    width: 200px;
  }
}

.section-row {
  display: flex;
  width: 100%;
  margin-bottom: 24px;

  .section-title {
    font-weight: bold;
    font-size: 32px;
    flex: 1 1 auto;
    text-transform: capitalize;
  }

  .section-controls-container {
    display: flex;
    align-items: center;

    ion-button {
      min-width: 160px;
      --border-radius: 3px;
      --background: var(--branding-blue);
      ion-icon {
        padding-right: 8px;
      }
    }

    .create-edit-controls-btn {
      min-width: 160px;
      --border-radius: 3px;
      --background: var(--branding-blue);
      ion-icon {
        padding-right: 8px;
        font-size: 32px;
      }
    }

    ion-searchbar {
      min-width: 80px;

      // ion-icon {
      //   right: 16px !important;
      //   left: auto !important;
      // }
    }
  }
}

.content-container {
  // margin: 48px;
  // margin: 48px auto;
  flex-direction: column;
  // align-items: center;

  .table-section-container {
    width: 100%;
  }
}

.title-container {
  // background-color: #ebebeb;
  padding: 8px 12px;
  // border: 1px solid #ebebeb;
  margin-bottom: 12px;
  .input-title {
    font-weight: bold;
  }
}

bh-input {
  max-width: 420px;
}

.char-counter {
  opacity: .7;
  padding-bottom: 16px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .section-row {
    display: block;
    padding: 0 16px;

    .section-controls-container {
      display: block;
      margin-top: 16px;

      ion-button {
        display: block;
      }
    }
  }

  .section-header {
    display: block;

    .section-title {
      margin-bottom: 16px;
    }

    .control-container {
      width: auto;
      display: block;

      ion-button {
        display: block;
      }
    }
  }

  .section-footer {
    ion-button {
      width: 100%;
    }
  }

  .trigger-event-container {
    display: block;
    text-align: center;

    .trigger-text-body {
      margin-right: 0;
      text-align: center;
    }

    ion-button {
      display: block;
      margin-top: 24px;
    }
  }

  .main-content-section {
    flex-direction: column;

    .details-container {
      margin-right: 0 !important;
      min-width: auto !important;
      max-width: unset !important;
      display: flex;
      flex-wrap: wrap;

      .details-item {
        flex: 1 1 auto;
      }
    }
  }
}
