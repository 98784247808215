/**
 * ID: bh-base-all-scss
 * Name: base.all.scss
 * Description: Base stylesheet for Baystate Health themes
 * Version: 5
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Adjust cta-button style
 * 2021-07-27 - MW - v3: Added missing alert danger class
 * 2022-05-25 - MW - v4: Split global.scss into base.all.scss, base.desktop.scss, base.mobile.scss, canned.scss
 * 2022-06-17 - MW - v5: Added fullpage-textarea class
 */

/*
 * Base CSS
 * ----------------------------------------------------------------------------
 * Base theming variants for all viewports.
 */

 * {
  font-family: "Open Sans", sans-serif;
}

html,
body,
form {
  // background: var(--background-color);
  color: var(--font-color);
}

// h1 {
//   font-size: 36px;
//   font-weight: 700;
// }

h1 {
  font-size: 3rem;
  margin: 48px 0 24px 0;
}

h2 {
  font-size: 28px;
  font-weight: 700;
  margin-top: 64px;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 48px;
}

h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 48px;
}

h5 {
  font-size: 16px;
  margin-top: 64px;
}

a {
  background-color: transparent;
  color: var(--ion-color-primary);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 30px;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 30px;
}

ion-header.dark ion-toolbar {
  --color: var(--font-color);
  --background: var(--header-color);
}

ion-content {
  &.safe-area-margin-top {
    --padding-top: var(--ion-safe-area-top);
  }

  &.safe-area-margin-bottom {
    --padding-bottom: var(--ion-safe-area-bottom);
  }

  &.transparent {
    --background: transparent;
  }
}

.safe-area-margin-top {
  --padding-top: var(--ion-safe-area-top);
  padding-top: var(--ion-safe-area-top);
}

.safe-area-margin-bottom {
  --padding-bottom: var(--ion-safe-area-bottom);
  padding-bottom: var(--ion-safe-area-bottom);
}

.content-container {
  display: flex;
  flex: 1 1 auto;
  min-height: 70vh;
  // justify-content: center;

  &.safe-area-margin-top {
    padding-top: var(--ion-safe-area-top) !important;
  }

  &.safe-area-margin-bottom {
    padding-bottom: var(--ion-safe-area-bottom) !important;
  }
}

.buttonCss {
  button.alert-button.remove-button{
    color: red;
  }
}

.side-tabs-content-container {
  display: flex;
  flex: 1 1 auto;
  // max-height: calc(100% - 56px);
  max-height: 100%;

  .side-tabs-option-pane {
    width: 350px;
    background: var(--item-color);
    padding: 16px 20px;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    border-left: 1px solid var(--ion-border-color);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    h1 {
      margin-top: 24px;
    }

    ion-list,
    .list-md,
    ion-item {
      --background: transparent;
      background: transparent;
    }

    ion-item {
      border-radius: 16px;
      transition: all 0.2s;

      ion-icon {
        color: var(--font-fade-color);
      }

      .details {
        font-size: 13px;
        opacity: 0.7;
      }

      &.selected {
        background: var(--shading-color);
        border-left: 2px solid var(--ion-color-primary);
      }
    }
  }

  .side-tabs-content-pane {
    flex: 1 1 auto;
    padding: 8px 32px;
    overflow-y: auto;
    max-height: 100%;

    .side-tabs-content-toolbar {
      padding: 4px;
      margin-bottom: 24px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .side-tabs-content-toolbar-label {
        font-size: 14px;
        margin-right: 16px;
      }

      select {
        padding: 4px;
        border: 1px solid var(--border-color);
        border-radius: 4px;
        font-size: 15px;
        margin-right: 24px;
      }
    }

    h4 {
      display: flex;
      align-items: center;

      .heading-label {
        flex: 1 1 auto;
      }
    }
  }
}

.close-button-wrapper {
  position: relative;
  min-height: 50px;

  a.close {
    height: 50px;
    width: 50px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 20px;
    right: 20px;
    transition: all 2s;
    z-index: 10;

    ion-icon {
      font-size: 32px;
    }

    &:hover {
      background: rgba(0, 0, 0, 1);
    }
  }
}

// ion-searchbar ion-icon {
//   right: 16px !important;
//   left: auto !important;
// }

// ion-searchbar {
//   .searchbar-search-icon.sc-ion-searchbar-ios.ios.hydrated{
//     margin-left: 0 !important;
//     text-align: start;
//   }
//   .searchbar-input .sc-ion-searchbar-md {
//     padding-left: 30px !important;
//     text-align: start;
//   }
//   ion-input::placeholder{
//     text-align: center;
// }
// }


.content-stage-header {
  display: flex;
  border-bottom: 1px solid var(--border-color);

  ion-segment {
    max-width: 400px;
  }

  .segment-toolbar {
    flex: 1 1 auto;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ion-button {
      margin-right: 16px;
      height: 34px;
    }

    .search-bar-wrapper {
      position: relative;
      flex: 1 1 auto;
      max-width: 300px;

      .search-bar {
        // border: 1px solid #ccc;
        border: none;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border-radius: 16px;
        padding: 7px 12px;
        outline: none;
        font-size: 14px;
        width: 99%;
        // width: 225px;
      }

      ion-icon {
        position: absolute;
        font-size: 24px;
        top: 4px;
        right: 10px;
      }
    }
  }
}

.app-icon {
  width: 32px;
  height: 32px;
  margin-right: 16px;

  &.small {
    height: 32px;
    width: 32px;
    margin-right: 12px;
  }
}

.content-stage-body {
  padding: 0;
}

.wide-modal {
  backdrop-filter: blur(4px);

  &.floating {
    .modal-wrapper {
      background: transparent;
      box-shadow: none;
    }
  }
}

.wide-modal {
  &.super-wide  &::part(content) {
    max-width: 1400px !important;
  }

  &.fullscreen &::part(content) {
    max-width: unset !important;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    max-width: 1100px;
  }

  &.floating {
    &::part(content) {
      width: 95%;
      height: 95%;
    }
  }
}


// .small-modal {
//   backdrop-filter: blur(4px);

//   &.floating {
//     .modal-wrapper {
//       background: transparent;
//       box-shadow: none;
//     }
//   }
// }

.small-modal {
  &.super-wide .modal-wrapper {
    max-width: 1400px !important;
  }

  &.fullscreen .modal-wrapper {
    max-width: unset !important;
  }

  .modal-wrapper {
    width: 100px;
    height: 100px;
    max-width: 1100px;
  }

  &.floating {
    .modal-wrapper {
      width: 95%;
      height: 95%;
    }
  }
}

ion-header.floating {
  box-shadow: none;
  --box-shadow: none;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 8px;
  min-height: 56px;
  background: var(--app-color-gradient);
  transition: all 0.3s;

  &.init {
  }

  &.header-ios ion-toolbar:last-of-type {
    --border-width: 0;
  }

  ion-toolbar {
    --background: transparent;
    color: #fff;
    text-shadow: 1px 1px 4px rgb(0 0 0 / 50%);

    ion-button {
      color: #fff;
    }
  }

  &.header-md::after {
    display: none;
  }
}

.wide-popover {
  --width: 90%;
  --min-width: 200px;
  --max-width: 400px;

  &::part(content) {
    --border-radius: 16px;
    border-radius: 16px;
  }
}

.translucent {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(2px);
}

ion-button {
  height: 40px;
  letter-spacing: normal;
  font-size: 14px;
  --border-radius: 4px;
  border-radius: 4px;
  // --border-radius: 30px;
  // border-radius: 16px;
}

ion-button,
ion-button .button-inner {
  text-transform: none;
  // font-size: 16px;
}

ion-button.button-solid,
ion-button.button-solid .button-inner {
  --color: #fff;
  color: #fff;
  --color-activated: #fff;
}

ion-button.button-outline {
  border-width: 1px;
  --border-width: 1px;
}

button,
button:focus {
  outline: none;
}

.width-175 {
  min-width: 175px;
}

.disabled {
  opacity: 0.3;
}

/* Material Checkbox */
.mat-checkbox {
  .mat-checkbox-frame {
    border-color: var(--border-color);
  }

  .mat-checkbox-label {
    color: var(--font-shade-color);
  }

  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: var(--ion-color-primary);
  }
}

/* Material DatePicker */
.material-datepicker-item .material-datepicker {
  width: 100%;

  &.mat-form-field.mat-form-field-appearance-legacy .mat-form-field-wrapper,
  &.mat-form-field-appearance-legacy .mat-form-field-flex .mat-form-field-infix,
  &.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;

    input {
      margin-bottom: 4px;
      caret-color: var(--font-color);
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    border-bottom-color: var(--border-color);
  }

  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    color: var(--ion-color-900);
    font-size: 20px;
  }

  .mat-form-field-underline {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &.mat-focused {
    .mat-form-field-underline {
      border-color: var(--ion-color-success);
      background-color: var(--ion-color-success);

      .mat-form-field-ripple {
        border-color: var(--ion-color-success);
        background-color: var(--ion-color-success);
      }
    }
    // &.mat-field-invalid {
    //   .mat-form-field-underline .mat-form-field-ripple {
    //     border-color: var(--ion-color-success);
    //     background-color: var(--ion-color-success);
    //   }
    // }
  }
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-calendar-table-header {
  color: var(--font-shade-color);
}

.mat-calendar-arrow {
  border-top-color: var(--border-color);
}

.mat-calendar-body-label,
.mat-calendar-body-cell-content {
  color: var(--font-color);
}

.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
  background-color: var(--ion-color-primary);
}

.mat-datepicker-content .mat-calendar {
  background: var(--shading-color);
  color: var(--font-shade-color);
}

.required-marker {
  font-size: 24px;
  margin-left: 8px;
  color: var(--ion-color-danger);
  height: 24px;
}

.error-message {
  border-radius: 2px 2px 4px 4px;
  padding: 6px;
  background: var(--ion-color-danger);
  color: #fff;
  margin-bottom: 12px;
  transition: all 0.3s;
  font-size: 14px;
  a {
    color: var(--ion-color-danger);
    font-weight: 600;
  }

  ion-icon {
    font-size: 20px;
    margin-right: 8px;
    float: left;
  }
}

/* App specific css */

.wide-viewport-container {
  max-width: 1100px;
  margin: auto;
  padding: 24px 16px 0 16px;
  padding: 0 16px;
  min-height: 60vh;
  // background: #fff;

  &.no-min-height {
    min-height: auto;
  }
}

/* Material Table */
table.mat-table {
  background: transparent;
  width: 100%;

  th {
    color: var(--font-color);
    font-weight: 600;
    background: var(--header-color);
  }

  td {
    color: var(--font-color);
    border-bottom: 1px solid #D6D6D6;
    transition: background 0.2s;
  }

  &.clickable {
    cursor: pointer;

    tr:hover td {
      background: var(--background-highlight-color);
    }

    tr:active td {
      background: var(--background-highlight-color);
    }
  }

  .mat-table-icon-container {
    font-size: 18px;
    color: var(--font-fade-color);
    display: inline-block;
    position: relative;
    margin-right: 16px;

    ion-badge {
      position: absolute;
      top: 0;
      right: -5px;
      height: 12px;
      width: 12px;
      border-radius: 30px;
      display: block;
    }
  }
}

pre {
  margin: 48px 0;
}

section {
  padding: 24px;
  margin: 48px 0;
}

.shaded {
  background: var(--background-shading-color);
  border-radius: 16px;
}

.hero-image {
  margin: 48px 0;
  height: 280px;
  background: no-repeat center center;
  background-size: cover;
}

bh-gridmenu-item {
  transition: all 0.2s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  height: 120px;
  position: relative;
}

bh-gridmenu-item:hover {
  cursor: pointer;
}

bh-gridmenu-item:nth-child(3),
bh-gridmenu-item:nth-child(6),
bh-gridmenu-item:nth-child(9),
bh-gridmenu-item:nth-child(12),
bh-gridmenu-item:nth-child(15),
bh-gridmenu-item:last-of-type {
  border-right-color: transparent;
}

bh-gridmenu-item:nth-last-child(-n + 3) {
  border-bottom-color: transparent;
}

bh-gridmenu-item-label {
  text-align: center;
  font-size: 12.5px;
  padding: 0 0 8px 0;
  color: var(--ion-color-dark-tint);
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  justify-content: center;
}

bh-gridmenu-item-icon {
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  svg,
  img,
  .icon-container {
    height: 45px;
    width: 45px;
    margin-top: 16px;
  }

  .icon-container {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  ion-icon {
    font-size: 50px;
  }
}

ion-slides h1 {
  align-items: flex-end !important;
  justify-content: flex-end !important;
  padding-right: 20px;
  padding-bottom: 20px;
  text-align: right;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  width: auto;
  left: 20px;
}

/* New CSS code can be added below here */

ion-header.small-header {
  background: var(--ion-color-secondary);
  --background: var(--ion-color-secondary);

  .wide-viewport-container {
    max-width: 1200px;
  }
}

.button-container {
  // min-width: 300px;
  // max-width: 400px;
  margin: auto;
  text-align: center;

  .standard-cta {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.standard-cta {
  height: 45px;
  font-size: 17px;
  letter-spacing: normal;
  // margin: 0 24px;
  margin-left: 24px;
  margin-right: 24px;
}

ion-list.list-md.indented {
  margin-left: 48px;
}

ion-menu {
  ion-list.list-md {
    margin-left: 0;
    transition: all 0.3s;
  }
}

ion-list-header ion-label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 30px;
}

ion-list-header ion-label.bold-label {
  font-weight: 800;
  font-size: 22px;
  margin-top: 24px;
  margin-bottom: 12px;
  letter-spacing: normal;
  text-transform: none;
}

// Improve spacing on items
.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h,
.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  font-family: "Open Sans", sans-serif;
}

ion-item h3,
.sc-ion-label-md-s h3 {
  font-weight: 600;
  font-size: 16px;
}

ion-item ion-icon {
  margin-right: 16px;
}

ion-item button,
ion-item {
  --padding-left: 8px;
  --border-radius: 8px;
  --background: transparent;
  transition: all 0.2s;
}

ion-item:not(.item-input):not(.no-hover):hover,
ion-item:not(.no-hover):hover {
  background: var(--item-highlight-color);
  --background-hover: var(--item-highlight-color);
}

.item.item-input {
  --background: transparent;
  border-radius: 4px;
  transition: all 0.2s;

  ion-label,
  &.sc-ion-label-ios-h,
  .sc-ion-label-ios-h,
  &.sc-ion-label-md-h,
  .sc-ion-label-md-h {
    --color: var(--font-shade-color);
    color: var(--font-shade-color);
    margin-bottom: 6px;
  }
}

.item-input:hover {
  --background: var(--shading-highlight-color);

  ion-label {
    --color: var(--font-color);
    color: var(--font-color);
  }
}

ion-segment {
  ion-segment-button {
    letter-spacing: normal;
    text-transform: none;
    --background: transparent;
  }
}

.sc-ion-segment-md-h.ion-color.sc-ion-segment-md-s > ion-segment-button {
  border-bottom: 1px solid #b2b2b2;
}

.sc-ion-segment-md-h.ion-color.sc-ion-segment-md-s > .segment-button-checked {
  font-weight: 600;
}

.ng-star-inserted.item-label.item.md.in-list.ion-activatable.ion-focusable.hydrated.item-radio-checked {
  ion-label {
    color: var(--ion-color-primary);
    --color: var(--ion-color-primary);
  }
}

ion-button.thin-outline {
  --border-width: 1px !important;
  font-weight: 600;
}

.list-message {
  padding: 0;
  margin: 12px 0;
  background: rgb(255, 240, 200);
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.4s;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;

  &.show {
    height: 90px;
  }

  &.saved {
    background: #e2ffd4;
    color: rgb(62, 143, 55);
  }
}

ion-toolbar {
  min-height: 56px;
  display: flex;
}

ion-title {
  font-size: 16px;

  &.title-align-left.ios {
    padding-left: 16px;
    text-align: left;
  }
}

.helper-box {
  border-radius: 16px;
  background: #edffd1;
  margin: 16px 0;
  padding: 12px 12px 12px 8px;
  position: relative;
  font-size: 14px;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;

  .helper-box-icon-wrapper {
    min-width: 60px;
    max-width: 60px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;

    ion-icon.helper-box-icon {
      font-size: 32px;
    }
  }

  .helper-box-text-wrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }
}

ion-spinner {
  height: 48px;
  width: 48px;
}

ion-textarea.fullpage-textarea {
  flex: 1 1 auto;
  height: 100%;

  .textarea-wrapper {
    display: flex;
    height: 100%;
  }

  textarea.native-textarea {
    padding: 12px !important;
    height: 100% !important;
  }
}

.alert-wrapper.sc-ion-alert-md,
.alert-wrapper.sc-ion-alert-ios {
  min-width: 300px;
  width: 80%;
  max-width: 600px;
}

.wide-alert {
  backdrop-filter: blur(4px);
  transition: backdrop-filter 0.2s;
}

.wide-alert.md {
  .alert-wrapper {
    min-width: 300px;
    max-width: 600px;
    border-top: 2px solid var(--ion-color-primary);
    --border-top: 2px solid var(--ion-color-primary);
  }

  &.error .alert-wrapper,
  &.danger .alert-wrapper {
    border-top: 2px solid var(--ion-color-danger);
    --border-top: 2px solid var(--ion-color-danger);
  }

  &.warning .alert-wrapper {
    border-top: 2px solid var(--ion-color-warning);
    --border-top: 2px solid var(--ion-color-warning);
  }

  &.success .alert-wrapper {
    border-top: 2px solid var(--ion-color-success);
    --border-top: 2px solid var(--ion-color-success);
  }

  .alert-sub-title.sc-ion-alert-md,
  .alert-title.sc-ion-alert-md,
  .alert-sub-title.sc-ion-alert-ios,
  .alert-title.sc-ion-alert-ios {
    font-weight: bold;
  }

  .alert-input-group.sc-ion-alert-md,
  .alert-message.sc-ion-alert-md,
  .alert-input-group.sc-ion-alert-ios,
  .alert-message.sc-ion-alert-ios {
    color: var(--font-shade-color);
  }

  .alert-button {
    -webkit-margin-end: 0;
    margin-inline-end: 0;
    min-width: 80px;
  }

  .alert-button.primary {
    background: var(--ion-color-primary);
    color: #fff;
  }

  &.reverse-buttons {
    .alert-button.sc-ion-alert-md:first-of-type,
    .alert-button.sc-ion-alert-ios:first-of-type {
      border-radius: 4px;
      padding-left: 16px;
      padding-right: 16px;
    }

    .alert-button.sc-ion-alert-md:last-of-type,
    .alert-button.sc-ion-alert-ios:last-of-type {
      background: inherit;
      // color: #fff !important;
      border-radius: 4px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .alert-button.sc-ion-alert-md,
  .alert-button.sc-ion-alert-ios {
    text-transform: none;

    .alert-button-inner.sc-ion-alert-md,
    .alert-button-inner.sc-ion-alert-ios {
      // text-align: center;
      justify-content: center;
    }
  }
}

.wide-alert.ios {
}

ion-loading .loading-wrapper.sc-ion-loading-md {
  background: #444;
  border-radius: 8px;

  ion-spinner {
    color: #fff;
  }
}

.dynamic-content h3 {
  margin-top: 32px;
}

.dynamic-content a {
  color: var(--ion-color-secondary) !important;
  font-size: 15px;
}

.dynamic-content li {
  margin-bottom: 12px;
  font-size: 15px;
}

.dynamic-content p {
  font-size: 15px;
}

// Band colors

.sun {
  background: #dfd9ff !important;
}

.mon {
  background: #d9edff !important;
}

.tue {
  background: #ffd9d9 !important;
}

.wed {
  background: #d9ffe4 !important;
}

.thu {
  background: #fffcd9 !important;
}

.fri {
  background: #dfd9ff !important;
}

.sat {
  background: #d9f9ff !important;
}

.footer-spacer {
  height: 60px;
}

.action-sheet-modal {
  .modal-wrapper.sc-ion-modal-md {
    // height: 70%;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    overflow: visible;
    border-radius: 16px;

    app-datepicker-modal {
      display: flex;
      flex-direction: column;
      overflow: visible;
    }
  }
}

ion-list {
  padding: 0;

  .heading {
    font-weight: 600;
    font-size: 15px;
    color: #000;
    margin-bottom: 8px;
  }

  .description {
    font-size: 14px;
  }

  ion-icon.top-aligned-icon {
    margin-top: 0;
  }
}

.divider-line {
  border-top: 1px solid #ccc;
  margin: 16px 0;
}

.segment-wrapper {
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px 0 32px 0;
  margin-bottom: 16px;
  border-radius: 4px;
}

bh-comments-item:last-of-type .comment-item {
  border-bottom-color: transparent !important;
}

.patient-item {
  align-items: flex-start;
  border-radius: 16px;
  transition: all 0.2s;
  margin-bottom: 24px;

  .patient-item-inner {
    flex: 1 1 auto;
  }

  h4 {
    margin-top: 16px;
    margin-bottom: 24px;
    font-size: 19px;
    font-weight: 600;
  }

  ion-icon {
    margin-top: 16px;
  }

  p {
    margin-bottom: 16px;
    font-weight: 300;
  }

  .patient-details {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .patient-details-cell {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    margin-right: 8px;
  }

  .patient-details-label {
    font-size: 14px;
    font-weight: 300;
  }

  .patient-details-value {
    font-size: 15px;
  }

  .patient-item-chevron {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    ion-icon {
      font-size: 24px;
      color: var(--font-fade-color);
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

.desktop-only,
.desktop-only-flex,
.desktop-only-inline {
  display: none;
}

.mobile-only {
  // use default attribute
}

.mobile-only-flex {
  display: flex;
}
